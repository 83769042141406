import React from "react";
import ThemeProvider from "react-bootstrap/ThemeProvider";
import classNames from "clsx";

import { Header } from "./header/Header";
import { Footer } from "./Footer";
import type { StyledComponentProps } from "./types";

import "../styles/styles.scss";
import * as styles from "../styles/ReadyToHire.module.scss";
import { BuildInfo } from "./BuildInfo";

interface SiteLayoutProps extends StyledComponentProps {
  children?: React.ReactNode;
}

const SiteLayout: React.FC<SiteLayoutProps> = ({ children }) => {
  return (
    <ThemeProvider>
      <Header
        // NOTE: Requires settings in styles.scss: #gatsby-focus-wrapper
        className="sticky-top user-select-none font-title"
      >
        <BuildInfo />
      </Header>
      <div
        className={classNames(
          styles["PagePanelContainer"],
          "smooth-scroll",
          "d-flex flex-column flex-fill"
        )}
      >
        <main
          className={classNames(
            "d-flex flex-column flex-fill",
            "position-relative"
          )}
        >
          {children}
        </main>
        <Footer />
      </div>
    </ThemeProvider>
  );
};

export default SiteLayout;
export type { SiteLayoutProps };
