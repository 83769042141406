import React from "react";
import { Link } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import classNames from "clsx";

import type { StyledComponentProps } from "./types";

import ReadyToHireLogoWhite from "../images/svg-inline/logos/readytohire_FINAL_white_2023-10-27.svg";
import FacebookIcon from "../images/svg-inline/icons/social/facebook.svg";
import LinkedInIcon from "../images/svg-inline/icons/social/linkedin.svg";
import InstagramIcon from "../images/svg-inline/icons/social/instagram.svg";

const Footer: React.FC<StyledComponentProps> = ({ className, style }) => {
  return (
    <footer
      className={classNames("py-5 text-bg-dark", className)}
      style={style}
    >
      <Container fluid={"xl"} className="px-4 gx-5">
        <Row>
          <Col xs={12} lg={3} className="mb-3">
            <ReadyToHireLogoWhite
              width={235}
              height={44}
              alt={"Ready to Hire | Powered by Cengage Group"}
              className={classNames("mt-0 mb-5 mx-0")}
            />
          </Col>

          <Col xs={12} lg={3} className="mb-3">
            <div className={"fw-semibold mb-2"}>Cengage</div>
            <ul className="nav flex-column mb-2">
              <li className="nav-item">
                <a
                  href="https://www.cengagegroup.com/"
                  className="nav-link px-0"
                  rel="external"
                  target="_blank"
                >
                  Cengage Group
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="https://www.cengagework.com/"
                  className="nav-link px-0"
                  rel="external"
                  target="_blank"
                >
                  Cengage Work
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="https://www.cengage.com/legal/#copyright"
                  className="nav-link px-0"
                  rel="external"
                  target="_blank"
                >
                  Copyright Notices
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="https://www.cengage.com/legal/#terms"
                  className="nav-link px-0"
                  rel="external"
                  target="_blank"
                >
                  Terms of Use
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="https://www.cengage.com/privacy"
                  className="nav-link px-0"
                  rel="external"
                  target="_blank"
                >
                  Privacy Statement
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="https://www.cengage.com/accessibility/"
                  className="nav-link px-0"
                  rel="external"
                  target="_blank"
                >
                  Accessibility
                </a>
              </li>
            </ul>
          </Col>

          <Col xs={12} lg={3} className="mb-3 vstack gap-2 me-lg-5">
            <p className={"small opacity-75"}>
              Copyright © Cengage Work. All rights reserved. The material on
              this site cannot be reproduced or redistributed unless you have
              obtained prior written permission from Cengage Work. Ready to Hire
              is a registered trademark and a part of Cengage Learning.
            </p>

            <div className={classNames("hstack gap-3")}>
              <a
                href="https://www.linkedin.com/showcase/ready-to-hire/"
                target="_blank"
              >
                <LinkedInIcon title="Linked In" width={24} height={24} />
              </a>
              <a
                href="https://www.facebook.com/readytohire.cengage/"
                target="_blank"
              >
                <FacebookIcon title="Facebook" width={24} height={24} />
              </a>
              <a
                href="https://www.instagram.com/ready.to.hire/"
                target="_blank"
              >
                <InstagramIcon title="Instagram" width={24} height={24} />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export { Footer };
