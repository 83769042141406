import React, { useState, useContext, useEffect, useCallback } from "react";
import { Link } from "gatsby";
import { globalHistory } from "@reach/router";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import classNames from "clsx";

import { useScrollPosition } from "../../hooks/useScrollPosition";
import { PrimaryNav } from "./PrimaryNav";
import type { StyledComponentProps } from "../types";
import type { HandlerProps } from "../../hooks/useScrollPosition";

import ReadyToHireLogo from "../../images/svg-inline/logos/readytohire_FINAL_color_2023-10-27.svg";

interface HeaderProps extends StyledComponentProps {
  children?: React.ReactNode;
}

const Header: React.FC<HeaderProps> = ({ className, style, children }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleResize = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  // Listen to the router state; if the route changes, close navigation
  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") handleResize();
    });
  }, [setIsOpen]);

  // Listen for window resize; close mobile navigation if open
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setIsOpen]);

  useScrollPosition({
    debounceMs: 250,
    handler: (props: HandlerProps) => {
      setIsScrolled(props.current["y"] > 3);
    },
  });

  return (
    <div
      className={classNames(
        className,
        isScrolled && "shadow"
        // "position-relative"
      )}
    >
      {children}
      <nav
        className="text-black"
        style={
          {
            backgroundColor: "var(--bs-gray-250)",
          } as React.CSSProperties
        }
      >
        <Container fluid={"xl"} className={"px-1"}>
          <ul
            className="nav flex-row"
            style={
              { "--bs-nav-link-padding-y": "0.25rem" } as React.CSSProperties
            }
          >
            <li className="nav-item ms-auto">
              <Nav.Link as={Link} to="/blog" className="nav-blog">
                Blog
              </Nav.Link>
            </li>
            <li className="nav-item">
              <Nav.Link as={Link} to="/about_us" className="nav-about">
                About
              </Nav.Link>
            </li>
          </ul>
        </Container>
      </nav>
      <Navbar
        as={"header"}
        bg="light"
        expand={"lg"}
        expanded={isOpen}
        onToggle={(expanded: boolean) => setIsOpen(expanded)}
        className={classNames("py-2")}
        style={{
          transition: `all 400ms ease-in`,
          ...style,
        }}
        role="banner"
      >
        <Container fluid={"xl"} className={"px-2"}>
          <Navbar.Brand as={Link} to="/">
            <ReadyToHireLogo
              width={235}
              height={44}
              alt={"Ready to Hire | Powered by Cengage Group"}
              className={classNames("mx-1 my-1")}
            />
            <span className="visually-hidden">Home</span>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="main-nav" className="border-0" />
          <PrimaryNav></PrimaryNav>
        </Container>
      </Navbar>
    </div>
  );
};

export { Header };
