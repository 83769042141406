// extracted by mini-css-extract-plugin
export var HasDesc = "PrimaryNav-module--HasDesc--d90e8";
export var Industries = "PrimaryNav-module--Industries--257ad";
export var PrimaryNavDesc = "PrimaryNav-module--PrimaryNavDesc--a09b5";
export var PrimaryNavIconWrapper = "PrimaryNav-module--PrimaryNavIconWrapper--55913";
export var PrimaryNavItem = "PrimaryNav-module--PrimaryNavItem--babaa";
export var PrimaryNavItemWrapper = "PrimaryNav-module--PrimaryNavItemWrapper--e04b5";
export var PrimaryNavTextWrapper = "PrimaryNav-module--PrimaryNavTextWrapper--e6949";
export var PrimaryNavTitle = "PrimaryNav-module--PrimaryNavTitle--79322";
export var Solutions = "PrimaryNav-module--Solutions--7da5f";
export var Subtitle = "PrimaryNav-module--Subtitle--642de";