import React from "react";
import { Link } from "gatsby";
import Offcanvas from "react-bootstrap/Offcanvas";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Form from "react-bootstrap/Form";
import classNames from "clsx";

import { MegaMenuItem } from "./MegaMenu";
import type { MegaMenuItemProps } from "./MegaMenu";

import ReadyToHireLogo from "../../images/svg-inline/logos/readytohire_FINAL_color_2023-10-27.svg";

import * as styles from "./PrimaryNav.module.scss";

interface PrimaryNavItemProps extends MegaMenuItemProps {
  title: string;
}

interface PrimaryNavProps {
  children?: React.ReactNode;
}

const PrimaryNavItem: React.FC<PrimaryNavItemProps> = ({
  title,
  children,
  className,
  ...rest
}) => {
  return (
    <MegaMenuItem
      {...rest}
      className={classNames(styles["PrimaryNavItem"], className)}
    >
      {title}
    </MegaMenuItem>
  );
};

const PrimaryNav: React.FC<PrimaryNavProps> = ({ children }) => {
  return (
    <Navbar.Offcanvas
      id="basic-navbar-nav"
      placement="end"
      scroll={false}
      style={{
        "--bs-offcanvas-width": "max(75vw, 350px)",
        "--bs-backdrop-opacity": 1,
        "--bs-backdrop-bg": "rgba(var(--bs-darker-rgb), 0.6)",
        "--bs-offcanvas-padding-x": 0,
        "--bs-offcanvas-padding-y": 0,
        "--bs-nav-link-padding-x": "1.5rem",
      }}
    >
      <Offcanvas.Header closeButton className="p-2 my-1 me-2">
        <Offcanvas.Title id={`main-nav`}>
          <Link to="/">
            <ReadyToHireLogo
              width={235}
              height={44}
              alt={"Ready to Hire | Powered by Cengage Group"}
              className={classNames("mx-1 my-1")}
            />
          </Link>
          <span className="visually-hidden">Menu</span>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body
        className={classNames("font-title user-select-none text-bg-light")}
      >
        <Nav
          className={classNames(
            "align-items-stretch align-items-md-middle",
            "justify-content-stretch flex-fill"
          )}
        >
          <Nav.Link as={Link} to="/" className="nav-home d-md-none">
            Home
          </Nav.Link>

          <Nav.Link as={Link} to="/partners" className="nav-partners">
            Hiring Partners
          </Nav.Link>

          <Nav.Link as={Link} to="/job_seekers" className="nav-jobseekers">
            Job Seekers
          </Nav.Link>

          <NavDropdown title="Solutions" id="solutions-dropdown">
            <PrimaryNavItem href="/create_new_talent" title="Create New Talent">
              Identify and train new talent through dedicated train-to-hire
              programs
            </PrimaryNavItem>
            <PrimaryNavItem
              href="/upskill_existing_talent"
              title="Upskill Existing Talent"
            >
              Train current employees or students to earn certifications and
              professional skills
            </PrimaryNavItem>
            <PrimaryNavItem
              href="/access_qualified_talent"
              title="Access Qualified Talent"
            >
              Select qualified students from local workforce and academic
              partners
            </PrimaryNavItem>
          </NavDropdown>

          <NavDropdown title="Industries" id="industries-dropdown">
            <PrimaryNavItem href="/healthcare" title="Healthcare">
              Healthcare
            </PrimaryNavItem>
            <PrimaryNavItem href="/it_cybersecurity" title="IT / Cybersecurity">
              IT / Cybersecurity
            </PrimaryNavItem>
            <PrimaryNavItem href="/skilled_trades" title="Skilled Trades">
              Skilled Trades
            </PrimaryNavItem>
            <PrimaryNavItem
              href="/advanced_manufacturing"
              title="Advanced Manufacturing"
            >
              Advanced Manufacturing
            </PrimaryNavItem>
          </NavDropdown>

          <div className="mx-3 ms-lg-auto me-lg-0">
            <Link
              to="/contact_us"
              className={classNames(
                "nav-contact btn btn-primary fw-600 ",
                // "align-self-lg-end",
                "mx-auto d-block",
                "mt-3 mt-md-0"
              )}
              style={{ maxWidth: 400 }}
            >
              Contact Us
            </Link>
          </div>
        </Nav>
      </Offcanvas.Body>
    </Navbar.Offcanvas>
  );
};

export { PrimaryNav };
