import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useContext,
} from "react";
import { createPortal } from "react-dom";
import { Link } from "gatsby";
import { Transition } from "react-transition-group";
import Container from "react-bootstrap/Container";
import NavLink from "react-bootstrap/NavLink";
import NavbarContext from "react-bootstrap/NavbarContext";
import NavDropdown, { NavDropdownProps } from "react-bootstrap/NavDropdown";
import Dropdown from "react-bootstrap/Dropdown";
import classNames from "clsx";
import { useDebounce, useEffectOnce } from "usehooks-ts";

import { useIsClient } from "../../hooks/useIsClient";
import type { StyledComponentProps } from "../types";

interface MegaMenuItemProps extends StyledComponentProps {
  href: string;
  children: React.ReactNode;
}

const MegaMenuItem: React.FC<MegaMenuItemProps> = ({
  href,
  children,
  className,
  style,
}) => {
  return (
    <NavDropdown.Item
      as={Link}
      to={href}
      className={classNames("text-wrap rounded-2", className)}
      style={style}
    >
      {children}
    </NavDropdown.Item>
  );
};

interface MegaMenuProps extends NavDropdownProps {
  mobileMaxWidth?: number;
}

const MegaMenu: React.FC<MegaMenuProps> = ({
  id,
  title,
  children,
  bsPrefix,
  className,
  rootCloseEvent,
  menuRole,
  disabled,
  active,
  renderMenuOnMount,
  menuVariant,
  style,
  mobileMaxWidth = 767,
  ...rest
}) => {
  const transitionTimeout = 400;
  const [showMenu, setShowMenu] = useState(false);
  const nodeRef = useRef(null);
  const calcIsMobile = useCallback(() => {
    setIsMobile(window.innerWidth <= mobileMaxWidth);
  }, [mobileMaxWidth]);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const debouncedIsMobile = useDebounce<boolean>(isMobile, 50);
  const navbarContext = useContext(NavbarContext);

  useEffectOnce(() => {
    calcIsMobile();
  });

  useEffect(() => {
    window.addEventListener("resize", calcIsMobile);
    return () => window.removeEventListener("resize", calcIsMobile);
  }, [calcIsMobile]);

  const { isClient, key } = useIsClient();

  return (
    <Dropdown
      {...rest}
      // Note: depends on definitions in _bootstrap-overrides
      className={classNames(className, "bs-nav-item", "has-megamenu")}
      onToggle={(nextShow, meta) => {
        setShowMenu(nextShow);
      }}
      show={showMenu}
      style={
        {
          "--megamenu-tx-timeout": `${transitionTimeout}ms`,
        } as React.CSSProperties
      }
    >
      <Dropdown.Toggle eventKey={null} childBsPrefix={bsPrefix} as={NavLink}>
        {title}
      </Dropdown.Toggle>

      {/* Note: different codepath for mobile vs. larger clients! */}

      {debouncedIsMobile ? (
        <div
          className={classNames(
            "vstack align-items-stretch justify-content-stretch",
            "gap-1"
          )}
          style={{ ...style }}
          onClick={() => navbarContext?.onToggle()}
        >
          {children}
        </div>
      ) : (
        <Transition nodeRef={nodeRef} in={showMenu} timeout={transitionTimeout}>
          {(state) => (
            <Dropdown.Menu
              role={menuRole}
              renderOnMount={renderMenuOnMount}
              rootCloseEvent={rootCloseEvent}
              variant={menuVariant}
              ref={nodeRef}
              className={classNames(`${state}`)}
            >
              <>
                {/* Be careful not to create a portal unless rendering in the browser */}
                <div key={key}>
                  {isClient &&
                    createPortal(
                      <div
                        style={
                          {
                            "--bs-backdrop-zindex": showMenu ? 2 : -1,
                            "--bs-backdrop-opacity": 1,
                            "--bs-backdrop-bg":
                              "rgba(var(--bs-darker-rgb), 0.6)",
                            backdropFilter: "blur(2px)",
                            transition: `all ${transitionTimeout}ms ease-in`,
                            transitionDuration: `${transitionTimeout}ms`,
                            transitionProperty: "filter, opacity",
                            transitionTimingFunction: "ease-in",
                          } as React.CSSProperties
                        }
                        className={classNames(
                          "modal-backdrop",
                          showMenu ? "show" : "fade"
                        )}
                      />,
                      window.document.body
                    )}
                </div>

                <Container fluid="xl">
                  <div
                    style={{
                      ...style,
                      display: "grid",
                      gridTemplateColumns: `repeat(${
                        Array.isArray(children) ? children.length : 1
                      }, minmax(0, 1fr))`,
                    }}
                  >
                    {children}
                  </div>
                </Container>
              </>
            </Dropdown.Menu>
          )}
        </Transition>
      )}
    </Dropdown>
  );
};

export { MegaMenu, MegaMenuItem };
export type { MegaMenuItemProps, MegaMenuProps };
